<!-- <div class="not-allowed">
    <h1>The link you provided has expired. Please contact the administrator for assistance.</h1>
  </div> -->

  <section class="page_404">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 ">
          <div class="col-sm-10 col-sm-offset-1  text-center">
            <div class="four_zero_four_bg">
              <!-- <h1 class="text-center ">Expired Link</h1> -->
  
            </div>
  
            <div class="contant_box_404">
              <h3 class="h2">
                Your Link Has Expired.Please contact to administrator.
              </h3>
  
              <!-- <p>Please contact to administrator</p> -->
  
              <!-- <a href="" class="link_404">Go to Home</a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
