import { Location } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, ResolveEnd, Router } from "@angular/router";
import {
  NgbAlertModule,
  NgbDatepickerModule,
  NgbDateStruct,
  NgbTimeStruct,
  NgbCalendar,
  NgbDatepicker,
  NgbTimepicker,
  NgbDatepickerConfig,
} from "@ng-bootstrap/ng-bootstrap";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import { CommonService } from "../services/common.service";
import { GoogleMapsLoaderService }  from '../services/google-maps-loader.service'
import { Subject, Observable, timer } from "rxjs";
import { WebcamImage, WebcamInitError, WebcamUtil } from "ngx-webcam";
import { AfterViewInit, ElementRef } from "@angular/core";
import {} from "googlemaps";
import { DatePipe } from "@angular/common";
import Swal from "sweetalert2";
import { environment } from "src/environments/environment";

import { EncryptionService } from '../services/encryption.service';



@Component({
  selector: 'app-universallink',
  templateUrl: './universallink.component.html',
  styleUrls: ['./universallink.component.css']
})
export class UniversallinkComponent implements OnInit {

  base_url = environment.baseUrl_angular;
  baseUrlVideo = environment.baseUrl_video;
  center: google.maps.LatLngLiteral;
  origin: google.maps.LatLngLiteral;
  destination: google.maps.LatLngLiteral;
  start: number;
  end: number;
  loadTime: number;
  ds: google.maps.DirectionsService;
  dr: google.maps.DirectionsRenderer;
  directionsService: google.maps.DirectionsService;
  directionsDisplay: google.maps.DirectionsRenderer;

  mapElement: any;
  nextWebcam: any;
  task_id: any;
  loaderActive: boolean = false;
  costumer_location: any;
  jobidval: any;
  workshopLocation: any;
  get_task_id: any;
  vendor_assigned_status: any;
  latsource: any;
  submit: boolean;
  job_id: string;
  workshop_location: any;
  vendor_location: any;
  cust_location: any;
  workshop_status: any;
  vendorInfo: Object;
  vendor_status: any;
  latlong: any;
  reachtime: any;
  policyData: any;
  reachedStatus: any;
  reached_time: any;
  start_time: any;
  reachtime_result: any;
  options: { enableHighAccuracy: boolean; timeout: number; maximumAge: number };
  vendor_latlong_status: any;
  vendId: any;
  vendor_name: any;
  vendor_mob_no: any;
  showVendorForm: boolean = false;
  updateStatusButton: boolean = false;
  showReachStatusButton: boolean = false;
  vendor_submit_details: any;
  cust_name: any;
  cust_mob_no: any;
  cust_addr: any;
  cust_reg_no: any;
  reachStatus: any;

  showMap: boolean = true;

  updateVideoButton: boolean = false;
  droppVendorUploadForm: boolean = false;

  videoMsgShow: boolean = false;

  taskStatus: FormGroup;
  @ViewChild("mapContainer") gmap: ElementRef;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  isTracking = false;
  map: google.maps.Map;
  lat1 = 19.1178548;
  lng2 = 72.8631304;

  // coordinates = new google.maps.LatLng(this.lat1, this.lng2);
  // mapOptions: google.maps.MapOptions = {
  //   center: this.coordinates,
  //   scrollwheel: true,
  //   disableDefaultUI: true,
  //   zoom: 15,
  // };

  title = "error";
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  directionList: boolean = true;
  public showWebcam = true;
  public allowCameraSwitch = true;
  //public switchCamera: Observable<boolean|string> = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  userDetailsForm: any;
  result: any;
  result_assign: any;
  errorMsg: any;
  successMsg: any;
  customer_location: any;
  vendorDetails: any;
  isImageTrue: boolean = false;
  vendor_info: boolean = false;
  showWebcamera: boolean = true;
  webcamImageEncoded: any;
  policy_details: any;
  display: any = "none";
  resultVendor: any;
  status_result: any;
  submitted: any;
  popupTitle: any;
  workshop_details: any;
  vendor_id: any;
  vendor_assign_id: any;
  vendor_details: any;
  customer_recent_no: any;
  idwatch: any;
  loadingactive: boolean = false;
  progress: any;
  updateReachStatusDisplay: any = "none";
  updateReachStatusForm: FormGroup;
  updateTaskStatusDisplay: any = "none";
  updateTaskStatusForm: FormGroup;
  vendorDetailsForm: FormGroup;
  uploadVideoTaskStatusForm: FormGroup;
  reach_time: any;
  uploadVideoStatusDisplay: any = "none";

  uploadDroppingVideoForm: FormGroup;
  uploadDroppVideoStatusDisplay: any = "none";

  currentDate = new Date();
  "MM/dd/yyyy h:mm:ss";
  taskId: any;
  scope: any;

  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];
  currentLat: any;
  currentLong: any;
  ids: any;
  row: any;
  showVendorAndCustomerDetails: boolean = false;
  isImageCaptured: boolean = false;
  get_id: any;
  pageExpire: boolean = false;
  customerLat: any;
  customerLong: any;
  intervalId: any;
  vendor_lat: any;
  vendor_longi: any;

  vendorDistance: any;
  vendorTravelTime: any;

  custShowroomDist: any;
  custtoShowroomTime: any;

  vendorShowroomDist: any;
  vendorShowroomTime: any;
  vendorDistanceStatus: boolean = false;
  meridian = true;
  details: any;

  viewPickUpVideoUrl: string = '';
  viewDropVideoUrl: string = '';

  linkTimeStamp: any;

  currentTimestamp: number;

  workshop_code: any;
  workshop_contact_person: any;
  workshop_mobile_no: any;
  
  selectedPickFile: File | null = null; 
  selectedDropFile: File | null = null; 
  uploadVideo: Array<any> = [];
  uploadDroopCustomerVideo: Array<any> = [];
  getLinkTimeStamp : any;

  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private config: NgbDatepickerConfig,
    private datePipe: DatePipe,
    private googleMapsLoader: GoogleMapsLoaderService,
    private encryptionService : EncryptionService
  ) {
    let currentDate = this.datePipe.transform(new Date(), "MM/dd/yyyy h:mm:ss");
  }

  ngOnInit(): void {
    this.task_id       = this.activatedRoute.snapshot.paramMap.get("id");
    this.linkTimeStamp = this.activatedRoute.snapshot.paramMap.get("timeStamp");
    console.log(this.activatedRoute.snapshot.paramMap);
    this.get_id = this.encryptionService.decryptPassword(this.task_id);
    this.getLinkTimeStamp = this.encryptionService.decryptPassword(this.linkTimeStamp);
    console.log(this.get_id);

    // console.log(this.get_id);
    this.vendorDetailsForm = this.formBuilder.group({
      image: [""],
      vendor_lat: this.currentLat,
      vendor_long: this.currentLong,
      vendor_id: [""],
      vendor_name: ["", [Validators.required]],
      // vendor_number: [
      //   "",
      //   [
      //     Validators.required,
      //     Validators.minLength(10),
      //     Validators.maxLength(10),
      //     // Validators.pattern('^(?:(?:\+91)|(?:91))?[789]\d{9}$'),
      //     Validators.pattern(/^[6-9]\d{9}$/),
      //   ],
      // ],
    });
    this.updateReachStatusForm = this.formBuilder.group({
      task_id: [""],
      reach_time: ["", [Validators.required]],
      comments: ["", [Validators.required]],
    });
    this.updateTaskStatusForm = this.formBuilder.group({
      taskId: [""],
      comments: ["", [Validators.required]],
      status: ["", [Validators.required]],
    });

    this.uploadVideoTaskStatusForm = this.formBuilder.group({
      taskId: [""],
      vendorId: [""],
      uploadVideo: ["", [Validators.required]],
    });

    this.uploadDroppingVideoForm = this.formBuilder.group({
      taskId: [""],
      vendorId: [""],
      uploadDroopCustomerVideo: ["", [Validators.required]],
    });
    
    this.loadTime = Date.now();
    timer(150000).subscribe(() => {
      // Map has expired, handle it here
      this.pageExpire == true;
      console.log("Map expired");
    });
    this.getpolicydata();
  }


  

  
  getpolicydata() {

    var get_id            = this.encryptionService.decryptPassword(this.task_id);
    this.linkTimeStamp     = Number(this.encryptionService.decryptPassword(this.linkTimeStamp));
    this.currentTimestamp = new Date().getTime();

    console.log(this.linkTimeStamp, this.currentTimestamp);
    

    // Compare the two times
    if (this.linkTimeStamp > this.currentTimestamp) {
    
      this.loaderActive     = true;
      let SendData = {
        task_id: get_id,
        linkTimeStamp : this.linkTimeStamp
      };
      this.commonService.getCustVendorDataByTask(SendData).subscribe((response) => {
        this.result = response;
        this.loaderActive = false;
        if (this.result.status == true) {
          this.cust_name = this.result.customer_name;
          this.cust_mob_no = this.result.customer_mobile_no;
          this.cust_addr = this.result.customer_addr;
          this.cust_reg_no = this.result.customer_regitration_no;

          this.job_id = this.result.job_id;

          this.vendor_assign_id = this.result.vendor_id;

          this.vendor_name = this.result.vendor_name;
          this.vendor_id = this.result.vendor_id;
          this.reached_time = this.result.reach_time;
          this.start_time = this.result.task_start_time;

          this.workshop_code = this.result.workshop_code;
          this.workshop_contact_person = this.result.workshop_contact_person;
          this.workshop_mobile_no = this.result.workshop_mobile_no;
    
          if (this.result.pickupVideourl == '' && this.result.vendor_id > 0) {
            this.updateVideoButton = true;
          }

          if (this.result.pickupVideourl != '') {
            this.viewPickUpVideoUrl = this.baseUrlVideo + this.result.pickupVideourl;
          }
      
          if (this.result.dropVideourl != '') {
            this.viewDropVideoUrl = this.baseUrlVideo + this.result.dropVideourl;
          }


          if (this.result.dropVideourl == '' && this.result.vendor_id > 0) {
            this.droppVendorUploadForm = true;
          }

          if (this.result.vendor_id < 0 || this.result.vendor_id == '' ) {
            this.videoMsgShow = true;
          }
          

        } else {
          
          Swal.fire({ icon: "error", title: this.result.message });

          setTimeout(() => {
            window.location.href = this.base_url+'/expire-page';
          }, 3000); // 3000 milliseconds = 3 seconds

        }

        //this.getTemporaryTrack();
      });

      console.log('testTime is greater');

    } else {

      Swal.fire({ icon: "error", title: 'Your link is expired please contact to administrator.' });

      setTimeout(() => {
        window.location.href = this.base_url+'/expire-page';
      }, 3000); // 3000 milliseconds = 3 seconds

    }

  }

  closePopup1() {
    this.updateTaskStatusDisplay = "none";
  }
  closePopup() {
    this.updateReachStatusDisplay = "none";
  }

 

  onUpdateTaskStatusDisplay() {
    this.popupTitle = "Update Task Status";
    this.updateTaskStatusDisplay = "block";
  }

  onUploadVideoDisplay() {
    this.uploadVideoStatusDisplay = "block";
  }

  onUploadVideoDisplay1() {
    this.uploadVideoStatusDisplay = "none";
  }

  onUploadDroppVideoDisplay() {
    this.uploadDroppVideoStatusDisplay = "block";
  }

  onUploadDroppVideoDisplay1() {
    this.uploadDroppVideoStatusDisplay = "none";
  }


  onFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      
      this.selectedPickFile = file;
      this.uploadVideo.push(file);
      // this.uploadVideoTaskStatusForm.patchValue({
      //   uploadVideo: file
      // });
    }
  }


  onUploadVideoForVendor() {
    if (this.uploadVideoTaskStatusForm.invalid) {
      return;
    }

    //this.getLinkTimeStamp     = Number(this.encryptionService.decryptPassword(this.linkTimeStamp));
    this.currentTimestamp = new Date().getTime();

    console.log(this.getLinkTimeStamp, this.currentTimestamp);
    

    // Compare the two times
    if (this.getLinkTimeStamp > this.currentTimestamp) {
    
   
    //console.log(Number(this.encryptionService.decryptPassword(this.linkTimeStamp)));
    
      let formData = new FormData();
      formData.append('task_id', this.get_id);
      formData.append('vendor_id', this.vendor_id);
      formData.append('video_status', 'pick');
      formData.append('link_time_stamp', this.getLinkTimeStamp);
      formData.append('uploadVideo', this.selectedPickFile);
      this.loaderActive = true;
      this.commonService.uploadVendorVideoUsingLink(formData).subscribe(response => {
        console.log(response);
        
        this.loaderActive = false;
        this.status_result = response;
        if (this.status_result.status === true) {
          console.log('abc');
          
          Swal.fire("Uploaded successfully");
          // Update the UI as needed
          this.uploadVideoStatusDisplay = "none";
          this.updateVideoButton = false;
          location.reload();
        } else {
          console.log('thtr');
          this.errorMsg = this.status_result.message;
          Swal.fire({ icon: "error", title: this.errorMsg });
        }
      }, error => {
        this.loaderActive = false;
        this.errorMsg = 'An error occurred';
        Swal.fire({ icon: "error", title: this.errorMsg });
      });
    } else {
      Swal.fire({ icon: "error", title: 'Your link is expired please contact to administrator.' });

      setTimeout(() => {
        window.location.href = this.base_url+'/expire-page';
      }, 3000); // 3000 milliseconds = 3 seconds

    }
  }

  onDroppFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.selectedDropFile = file;
       this.uploadDroopCustomerVideo.push(file);
      // this.uploadDroppingVideoForm.patchValue({
      //   uploadDroopCustomerVideo: file
      // });
    }
  }

  onUploadDroppingVideoForVendor () {
    if (this.uploadDroppingVideoForm.invalid) {
      return;
    }
    
  
    this.currentTimestamp = new Date().getTime();

    console.log(this.getLinkTimeStamp, this.currentTimestamp);
    // Compare the two times
    if (this.getLinkTimeStamp > this.currentTimestamp) {

      let formData = new FormData();
      formData.append('task_id', this.get_id);
      formData.append('vendor_id', this.vendor_id);
      formData.append('video_status', 'drop');
      formData.append('link_time_stamp', this.getLinkTimeStamp);
      formData.append('uploadVideo', this.selectedDropFile);
      this.loaderActive = true;
      this.commonService.uploadVendorVideoUsingLink(formData).subscribe(response => {
        console.log(response);
        
        this.loaderActive = false;
        this.status_result = response;
        if (this.status_result.status === true) {
          Swal.fire("Uploaded successfully");
          // Update the UI as needed
          this.uploadVideoStatusDisplay = "none";
          this.updateVideoButton = false;
          location.reload();
        } else {
          this.errorMsg = this.status_result.message;
          Swal.fire({ icon: "error", title: this.errorMsg });
        }
      }, error => {
        this.loaderActive = false;
        this.errorMsg = 'An error occurred';
        Swal.fire({ icon: "error", title: this.errorMsg });
      });
    } else {
      
      Swal.fire({ icon: "error", title: 'Your link is expired please contact to administrator.' });

      setTimeout(() => {
        window.location.href = this.base_url+'/expire-page';
      }, 3000); // 3000 milliseconds = 3 seconds

    }
  }
 




  

 




}
