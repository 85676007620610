 export const environment = {
  sessionExpiredSeconds: 43200000,
  googleMapsApiKey: "AIzaSyBf4dk4gKMYMQSm1ukYGK1M5GTZDphfG3I",

  // ---- Begin Tvs Live ----

  production: true,
  baseUrl: 'https://crm-api.tvsservice.com/api/',
  baseUrl_angular: 'https://crm.tvsservice.com/#/',
  baseUrl_video : 'https://crm-api.tvsservice.com/',
  apiurl: 'https://crm-api.tvsservice.com/api/',
  mainJsPath: '/TVS/assets/front/js/main.js',
  CaptchaSiteKey:'6LdiDtIZAAAAAHk13aDi4GWPL-QUBkAihnWKYS2x',
  minPasswordLength: 8,
  maxPasswordLength: 12,
  permissionDeniedMsg: 'You do not have privilege for this page.',
  sessionTimeoutInSeconds: 3600,

  userLogout:'/admin/logout',
  adminLogout:'/admin/logout',

  TodayCase:'/#/admin/today-cases',
  AllCases:'/#/admin/allclaimdetails',
  OpenCases:'/#/admin/opencases',
  CustomerOnboarded:'/#/admin/customeronboarded',
  VendorAssigned:'/#/admin/vendorassigned',
  AssistanceScheduled:'/#/admin/assistancescheduled',
  VendorDeclined:'/#/admin/vendordeclined',
  TaskCompleted:'/#/admin/taskcompleted',
  HoldCases:'/#/admin/hold-cases',

  //---- End Tvs Live ----
};
