<div class="content container-fluid">
  <div class="page-header">
    <div class="row align-items-center">
      <div class="col-12">
        <img src="assets/front/img/tvs.png" width="180px">
      </div>
    </div>
  </div>
  <section id="portfolio">
    <div class="row">
      <div class="col-md-12">
        <div class="card shadowbox">
          <div class="card-body pb-0">
            <div class="table-responsive">
              <div class="row">
                <div class="col-lg-12">
                  <!-- <div class="col-md-12">
                    <div *ngIf="result && result.status" class="alert alert-success">
                      {{result.message}}
                    </div>
                    <div *ngIf="result && !result.status " class="alert alert-danger">
                      {{result.message}}
                    </div>
                  </div> -->
                </div>
                <div class="clearfix"></div>
              </div>

              <div class="row"  *ngIf="showVendorForm">

                <div class="col-lg-12">
                  <div class="col-md-12">
                    <div class="row">
                    <div class="col-md-6">
                      <webcam [height]="200" [width]="200" [trigger]="triggerObservable"
                        (imageCapture)="handleImage($event)">
                      </webcam>
                      <br>
                      <button class="actionBtn btn btn-default green" (click)="triggerSnapshot()" >
                        Take a snapshot
                      </button>
                      &nbsp;
                      <span class="text-danger" *ngIf="vendorDetailsForm?.valid && !isImageCaptured">
                        Please capture your image
                      </span>
  
                    </div>
                    <div class="col-md-6">
                      <div class="snapshot" *ngIf="webcamImage">
                        <h2>Here is your image!</h2>
                        <img [src]="webcamImage.imageAsDataUrl" style="height: 180px;" />
                      </div>
                      <img [width]="100" />
                      <input type="image" id="image_encoded" type="hidden" style="width: 100px;" />
                    </div>
                    </div>

                    
                  </div>
                </div>
                <div class="clearfix"></div>
              </div>

              <div class="row"  *ngIf="showVendorForm">
                <div class="col-md-3">
                  <form [formGroup]="vendorDetailsForm" (ngSubmit)="onSaveVendorDetails()">
                    <input type="hidden" formControlName="image">
                    <input type="hidden" formControlName="vendor_lat">
                    <input type="hidden" formControlName="vendor_long">
                    <input type="hidden" formControlName="vendor_id">
                    <label for="vendor_name">Vendor Name<span class="text-danger">*</span></label> <br>
                    <input type="text" class="form-control" formControlName="vendor_name" >
                    <br>
                    <div
                      *ngIf="vendorDetailsForm.get('vendor_name').invalid && (vendorDetailsForm.get('vendor_name').dirty || vendorDetailsForm.get('vendor_name').touched)">
                      <span class="text-danger" *ngIf="vendorDetailsForm.get('vendor_name').errors">Vendor
                        name is
                        required</span>
                    </div>
                   <!--  <label for="vendor_number">Vendor Mobile Number<span class="text-danger">*</span></label> <br>
                    <input type="text" class="form-control" formControlName="vendor_number" id="vendor_number" maxlength="10">
                    <br>
                    <div class="text-danger"
                      *ngIf="vendorDetailsForm.get('vendor_number').invalid && (vendorDetailsForm.get('vendor_number').dirty || vendorDetailsForm.get('vendor_number').touched)">
                      <span *ngIf="vendorDetailsForm.get('vendor_number').errors">Please enter valid mobile number.</span>
                    </div> -->
                    <button type="submit" class="btn btn-default green " style="margin-bottom: 24px;"
                      [disabled]="vendorDetailsForm.invalid">Submit</button>
                  </form>
                </div>
                <div class="clearfix"></div>
              </div>
              <div class="row">
                <div class="col-md-6" *ngIf="showMap"> 
                  <div class="cmap-wrap p-0">
                    <div #mapContainer id="map"></div>
                    <strong>
                      <h4 #mapContainer id="msg"></h4>
                    </strong>
                    <div #mapContainer id="directionsList" *ngIf="directionList"></div>
                    <div id="output" style="display: none"></div>
                  </div>
                </div>
                <div class="col-md-6" >
                  <div >
                  <h3 *ngIf="
                  policy_details?.reach_status != 'reached' &&
                  policy_details?.reach_status != '' &&
                  policy_details?.status_id != '14'
                "><img alt="IMG" class="image-vendor" src="assets/front/img/vendar-icon.png" >Vendor Details :</h3>
                  <ul >
                    <li>
                      <h5><strong>Vendor Name</strong>: {{ vendor_name || "NA"}} </h5>
                    </li>
                  <!--   <li>
                      <h5><strong>Vendor Mobile Number</strong>: {{ vendor_mob_no || "NA"}} </h5>
                    </li> -->
                    <li>
                      <h5><strong>Task Start Time</strong>: {{ start_time || "NA"}} </h5>
                    </li>
                    <li>
                      <h5><strong>Reach Time </strong>: {{ reached_time || "NA"}}</h5>
                    </li>
                    <!-- <li>
                      <h5><strong>Reach Status </strong>: {{ reachedStatus  || "NA"}}</h5>
                    </li> -->
                 
                  </ul>
                </div>
                  <h3 *ngIf="policy_details"><img   class="image-customer" alt="IMG" src="assets/front/img/customer.png">
                    Customer Details :</h3>
                  <ul>
                   
                    <li>
                      <h5> <strong>Customer Name</strong> : {{ cust_name || "NA"}}</h5>
                    </li>
                    <li>
                      <h5><strong>Mobile No </strong> : {{ cust_mob_no || "NA"}}</h5>
                    </li>
                    <li>
                      <h5><strong>Customer Address</strong> : {{cust_addr || "NA"}}</h5>
                    </li>
                    <li>
                      <h5><strong>Vehicle Registration No.</strong> : {{ cust_reg_no || "NA"}}</h5>
                    </li>
                  </ul>

                  <h3 *ngIf="policy_details"><img  class="image-workshop" alt="IMG" src="assets/front/img/workshop.jpg" >Workshop Details :</h3>
                  <ul>
                   
                    <li>
                      <h5><strong>Workshop Code</strong> : {{workshop_details?.workshop_code || "NA"}}</h5>
                    </li>
                    <li>
                      <h5><strong>Workshop Name </strong>:
                        {{workshop_details?.contact_person || "NA"}} </h5>
                    </li>
                    <li>
                      <h5> <strong>Mobile No</strong>: {{workshop_details?.mobile || "NA"}}</h5>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <div class="col-md-4">
                        <button class="btn btn-default green"  *ngIf="showReachStatusButton" style="width: 110%;margin-bottom: 10px;"
                          (click)="onUpdateReachStatusDisplay()"><span>Update Reach Status</span></button>
                      </div>
                    </li>

                    <li>
                      <div class="col-md-4">
                        <button class="btn btn-default green"  *ngIf="updateStatusButton"  style="width: 110%;" (click)="onUpdateTaskStatusDisplay()"><span>Update Task
                            Status</span></button>
                      </div>
                    </li>

                   <!--  <li>
                      <div class="col-md-4">
                        <button class="btn btn-default green"  *ngIf="updateVideoButton"  style="width: 110%;" (click)="onUploadVideoDisplay()"><span>Upload Pick Video</span></button>
                      </div>
                    </li>

                    <li>
                      <div class="col-md-4">
                        <a *ngIf="viewPickUpVideoUrl" style="width: 110%;" class="btn btn-default green"  [href]="viewPickUpVideoUrl" target="_blank"> View Pick Video</a>
                      </div>
                    </li>

                    <li>
                      <div class="col-md-4">
                        <button class="btn btn-default green"  *ngIf="droppVendorUploadForm"  style="width: 110%;" (click)="onUploadDroppVideoDisplay()"><span>Upload Drop Video</span></button>
                      </div>
                    </li>

                    <li>
                      <div class="col-md-4">
                        <a *ngIf="viewDropVideoUrl" class="btn btn-default green" [href]="viewDropVideoUrl" style="width: 110%;" target="_blank"> View Drop Video </a>
                      </div>
                    </li>
 -->
                   
                  </ul>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-center" style="height: 24px;">
          <p style="color:whitesmoke !important ; font-size:small !important;">© 2019. All rights reserved. |
            Designed by
            <a style="color:red !important" href="https://www.indicosmic.com/"
              title="Indicosmic Capital Private Limited"><span>Indicosmic Capital Private Limited</span></a>
          </p>
        </div>
      </div>
    </div>
  </footer>

  <div class="modal modal-overlaybg" tabindex="1" role="dialog" [ngStyle]="{ display: updateTaskStatusDisplay }">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ popupTitle }}</h4>
          <button (click)="closePopup1()" type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <form [formGroup]="updateTaskStatusForm" (ngSubmit)="onUpdateTaskStatus()">
          <div class="form-group col-lg-12">
            <div class="form-group">
              <input type="hidden" formControlName="taskId">
              <label>Comments<span class="text-danger">*</span></label>
              <textarea formControlName="comments" type="text" class="form-control"></textarea>
              <div style="color:red"
                *ngIf="updateTaskStatusForm.get('comments').invalid && (updateTaskStatusForm.get('comments').dirty || updateTaskStatusForm.get('comments').touched)">
                <span *ngIf="updateTaskStatusForm.get('comments').errors?.required">Comments is required</span>
              </div>
            </div>
            <br />
            <div class="form-group" style="margin-top: 10px">
              <div class="form-group">
                <label>Status<span class="text-danger">*</span></label>
                <select formControlName="status" class="form-control ">
                  <option value="">Select Status</option>
                  <option value="completed">Completed</option>
                  <option value="decline">Declined</option>
                  <option value="additional_assistance_required">
                    Additional Assistance Required
                  </option>
                </select>
              </div>
            </div>
          </div>
          <button type="button" style="margin-bottom:20px;margin-left: 10px;" class="btn btn-custom green"
            (click)="closePopup1()" data-dismiss="modal">
            Close</button>&nbsp;
          <button class="btn btn-custom green" style="margin-bottom:20px;margin-left: 10px;" type="submit"
            [disabled]="updateTaskStatusForm.invalid">Save</button>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="modal modal-overlaybg" tabindex="1" role="dialog" [ngStyle]="{ display: updateReachStatusDisplay }">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ popupTitle }}</h4>
        <button (click)="closePopup()" type="button" class="close" data-dismiss="modal">
          &times;
        </button>
      </div>
      <form [formGroup]="updateReachStatusForm" (ngSubmit)="onUpdateReachStatus()">
        <div class="form-group col-lg-12">
          <div class="form-group">

            <div class="form-group" style="margin-top: 10px">
              <input type="hidden" formControlName="task_id">
              <label>Reached Time</label><span style="color: red">*</span>

              
              <div class="row">

                <div class="col-md-6">

                  <input class="form-control"
                  formControlName="date_pick" placeholder="Select Date(yyyy-mm-dd)"  (ngModelChange)="onDateChange($event)" [(ngModel)]="selectedDate" type="date" [min]="minDate" [max]="maxDate"
                   />
                       
                  <span class="text-danger" style="margin-left: 10px; margin-top: 10px" *ngIf="
                        (updateReachStatusForm.controls.date_pick.touched || submit) &&
                        updateReachStatusForm.controls.date_pick.errors?.required
                      ">
                      Date is required
                    </span>
                </div>

                <div class="col-md-6 reach_time">

                  <ngb-timepicker
                    formControlName="reach_time"
                    [meridian]="meridian" ></ngb-timepicker>

                  <div
                    class="text-danger"
                    *ngIf="
                    updateReachStatusForm.get('reach_time').invalid &&
                      (updateReachStatusForm.get('reach_time').dirty ||
                      updateReachStatusForm.get('reach_time').touched)
                    "
                  >
                    <span
                      *ngIf="
                      updateReachStatusForm.get('reach_time').errors
                          ?.required
                      "
                      >Select Time is required</span
                    >
                  </div>

                </div>

              </div>

              <!-- <ngb-timepicker  formControlName="reach_time" [meridian]="meridian"></ngb-timepicker>
              <div style="color:red"
                *ngIf="updateReachStatusForm.get('reach_time').invalid && (updateReachStatusForm.get('reach_time').dirty || updateReachStatusForm.get('reach_time').touched)">
                <span *ngIf="updateReachStatusForm.get('reach_time').errors?.required">Reach Time is required</span>
              </div> -->

            </div>
          </div>
          <label>Comments</label><span style="color: red">*</span>
          <textarea formControlName="comments" type="text" class="form-control"></textarea>
          <div style="color:red"
            *ngIf="updateReachStatusForm.get('comments').invalid && (updateReachStatusForm.get('comments').dirty || updateReachStatusForm.get('comments').touched)">
            <span *ngIf="updateReachStatusForm.get('comments').errors?.required">Comments is required</span>
          </div>
          <div style="margin-top:20px">
            <button type="button" class="btn btn-custom green" (click)="closePopup()" data-dismiss="modal">
              Close</button>&nbsp;
            <button class="btn btn-custom green" type="submit" [disabled]="updateReachStatusForm.invalid">
              Save</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>


<div class="modal modal-overlaybg" tabindex="1" role="dialog" [ngStyle]="{ display: uploadVideoStatusDisplay }">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Upload 360 View Video</h4>
        <button (click)="onUploadVideoDisplay1()" type="button" class="close" data-dismiss="modal">
          &times;
        </button>
      </div>
      <form [formGroup]="uploadVideoTaskStatusForm" (ngSubmit)="onUploadVideoForVendor()">
        <div class="form-group col-lg-12">
          <div class="form-group">
            <input type="hidden" formControlName="taskId">
            <label>Upload Video<span class="text-danger">*</span></label>
            <input type="file" formControlName="uploadVideo" id="file" class="form-control" (change)="onFileChange($event)">
            <div style="color:red"
              *ngIf="uploadVideoTaskStatusForm.get('uploadVideo').invalid && (uploadVideoTaskStatusForm.get('uploadVideo').dirty || uploadVideoTaskStatusForm.get('uploadVideo').touched)">
              <span *ngIf="uploadVideoTaskStatusForm.get('uploadVideo').errors?.required">File is required</span>
            </div>
          </div>
          <br />
        </div>
        <button type="button" style="margin-bottom:20px;margin-left: 10px;" class="btn btn-custom green"
          (click)="onUploadVideoDisplay1()" data-dismiss="modal">
          Close</button>&nbsp;
        <button class="btn btn-custom green" style="margin-bottom:20px;margin-left: 10px;" type="submit"
          [disabled]="uploadVideoTaskStatusForm.invalid">Save</button>
      </form>
    </div>
  </div>
</div>

<div class="modal modal-overlaybg" tabindex="1" role="dialog" [ngStyle]="{ display: uploadDroppVideoStatusDisplay }">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Upload 360 View Dropping Video To Customer</h4>
        <button (click)="onUploadDroppVideoDisplay1()" type="button" class="close" data-dismiss="modal">
          &times;
        </button>
      </div>
      <form [formGroup]="uploadDroppingVideoForm" (ngSubmit)="onUploadDroppingVideoForVendor()">
        <div class="form-group col-lg-12">
          <div class="form-group">
            <input type="hidden" formControlName="taskId">
            <label>Upload Video<span class="text-danger">*</span></label>
            <input type="file" formControlName="uploadDroopCustomerVideo" id="file" class="form-control" (change)="onDroppFileChange($event)">
            <div style="color:red"
              *ngIf="uploadDroppingVideoForm.get('uploadDroopCustomerVideo').invalid && (uploadDroppingVideoForm.get('uploadDroopCustomerVideo').dirty || uploadDroppingVideoForm.get('uploadDroopCustomerVideo').touched)">
              <span *ngIf="uploadDroppingVideoForm.get('uploadDroopCustomerVideo').errors?.required">File is required</span>
            </div>
          </div>
          <br />
        </div>
        <button type="button" style="margin-bottom:20px;margin-left: 10px;" class="btn btn-custom green"
          (click)="onUploadDroppVideoDisplay1()" data-dismiss="modal">
          Close</button>&nbsp;
        <button class="btn btn-custom green" style="margin-bottom:20px;margin-left: 10px;" type="submit"
          [disabled]="uploadDroppingVideoForm.invalid">Save</button>
      </form>
    </div>
  </div>
</div>

<app-loader [loaderActive]="loaderActive"></app-loader>