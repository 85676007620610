import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-expire-page',
  templateUrl: './expire-page.component.html',
  styleUrls: ['./expire-page.component.css']
})
export class ExpirePageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
