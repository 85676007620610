import { Location } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, ResolveEnd, Router } from "@angular/router";
import {
  NgbAlertModule,
  NgbDatepickerModule,
  NgbDateStruct,
  NgbTimeStruct,
  NgbCalendar,
  NgbDatepicker,
  NgbTimepicker,
  NgbDatepickerConfig
} from "@ng-bootstrap/ng-bootstrap";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import { CommonService } from "../services/common.service";
import { GoogleMapsLoaderService } from "../services/google-maps-loader.service";
import { Subject, Observable } from "rxjs";
import { WebcamImage, WebcamInitError, WebcamUtil } from "ngx-webcam";
import { AfterViewInit, ElementRef } from "@angular/core";
import {} from "googlemaps";
import { DatePipe } from "@angular/common";
import Swal from "sweetalert2";
import { environment } from "src/environments/environment";
import { EncryptionService } from "../services/encryption.service";

@Component({
  selector: "app-customer-location-details",
  templateUrl: "./customer-location-details.component.html",
  styleUrls: ["./customer-location-details.component.css"],
})
export class CustomerLocationDetailsComponent implements OnInit, AfterViewInit {
  base_url = environment.baseUrl_angular;
  center: google.maps.LatLngLiteral;
  origin: google.maps.LatLngLiteral;
  destination: google.maps.LatLngLiteral;
  start: number;
  end: number;

  // options: google.maps.MapOptions = {
  //   mapTypeId: google.maps.MapTypeId.ROADMAP,
  //   scrollwheel: true,
  //   disableDefaultUI: true,
  //   disableDoubleClickZoom: true,
  //   zoom: 12,
  //   mapId: "5f9700d7ed39be09",
  //   // mapId:""
  // };
  ds: google.maps.DirectionsService;
  dr: google.maps.DirectionsRenderer;
  directionsService: google.maps.DirectionsService;
  directionsDisplay: google.maps.DirectionsRenderer;

  mapElement: any;
  nextWebcam: any;
  task_id: any;
  loaderActive: boolean = false;
  costumer_location: any;
  vendor_track_location: any;
  jobidval: any;
  workshopLocation: any;
  get_task_id: any;
  vendor_assigned_status: any;
  latsource: any;
  submit: boolean;
  job_id: string;
  workshop_location: any;
  vendor_location: any;
  cust_location: any;
  workshop_status: any;
  customer_address: any;
  cust_Details: any;
  latlongInfo: any;
  waypoint: { latitude: any; longitude: any };
  latlong: any;
  workshopInfo: any;

  checkCustomerAdd: any;
  response_data: any;

  @ViewChild("mapContainer", { static: false }) gmap: ElementRef;
  @ViewChild("searchInput", { static: false }) searchInput: ElementRef;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  isTracking = false;
  map: google.maps.Map;
  lat1 = 19.1178548;
  lng2 = 72.8631304;
  // coordinates = new google.maps.LatLng(this.lat1, this.lng2);

  // coordinates = new google.maps.LatLng(this.lat1, this.lng2);
  // mapOptions: google.maps.MapOptions = {
  //   center: this.coordinates,
  //   zoom: 12,
  // };

  title = "gfgangularwebcam";

  public deviceId: string;
  userDetailsForm: any;
  result: any;

  customer_location: any;
  vendorDetails: any;
  policy_details: any;
  display: any = "none";
  resultVendor: any;
  status_result: any;
  submitted: any;
  popupTitle: any;
  workshop_details: any;
  vendor_id: any;
  addresses: string[] = [];
  vendor_details: any;
  customer_recent_no: any;
  showAddressList: boolean = false;

  customerLocationForm: FormGroup;
  displayLocationModal: any = "none";
  reach_time: any;
  currentDate = new Date();
  taskId: any;
  setaddress: any;
  scope: any;
  cust_Info: any;
  currentLat: any;
  currentLong: any;
  ids: any;
  row: any;
  showVendorAndCustomerDetails: boolean = false;

  customerName: any;
  custCurrAddr: any;
  customerMobileNo: any;
  encryptedMobileNumber: any;
  registrationNo: any;

  customerLat: any;
  customerLong: any;

  vendor_lat: any;
  vendor_longi: any;

  vendor_reach_status: boolean = false;

  encrytJobId: string;
  isEyeIconVisible: boolean = true;

  expiryData: any;

  currentTimestamp: number;
  currentDateTime: any;
  customerMaskedMobileNo: any;
  displayMobileNo: string = "";

  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private config: NgbDatepickerConfig,
    private datePipe: DatePipe,
    private googleMapsLoader: GoogleMapsLoaderService,
    private encryptionService: EncryptionService
  ) {
    this.currentDateTime = this.datePipe.transform(
      new Date(),
      "yyyy-MM-dd HH:mm:ss"
    );
  }

  ngOnInit(): void {
    localStorage.clear();
    this.job_id = this.activatedRoute.snapshot.paramMap.get("id");

    this.job_id = atob(this.job_id);

    this.encrytJobId = this.activatedRoute.snapshot.paramMap.get("id");

    // this.get_id = atob(this.task_id);
    // console.log(this.get_id);

    this.getLocation();
    // this.getVendorIdByTaskId();
    // this.getWorkshopDetails();

    this.customerLocationForm = this.formBuilder.group({
      customer_addrss: [""],
      city: [""],
    });
    this.googleMapsLoader
      .load()
      .then(() => {
        this.mapInitializer();
      })
      .catch((err) => {
        console.warn("Google Maps failed to load", err);
      });
    // const mapProperties = {
    //   center: new google.maps.LatLng(this.lat1, this.lng2),
    //   zoom: 15,
    //   mapTypeId: google.maps.MapTypeId.ROADMAP,
    // };

    // if (google && google.maps) {
    //   // this.initAutocomplete();
    //   //console.warn("call");
    // } else {
    //   //console.warn("Google Maps API not loaded yet.");
    // }
    // this.ds = new google.maps.DirectionsService();
    // this.dr = new google.maps.DirectionsRenderer();

    // console.log("ngOnInit: Starting to load Google Maps");
    // this.googleMapsLoader
    //   .load()
    //   .then(() => {
    //     console.log("Google Maps loaded, initializing map");
    //     this.mapInitializer();
    //   })
    //   .catch((err) => {
    //     console.error("Google Maps failed to load", err);
    //   });

    // setInterval(() => {
    //   this.getLocation();
    //   console.log("vendorlat",this.currentLat,"vendorlong",this.currentLong);
    //   var j = 0;
    //   this.idwatch = navigator.geolocation.watchPosition((position) => {
    //       // alert('in');
    //       this.showTrackingPosition(position);
    //       j++;

    //   },

    //   (err) =>console.log(err),
    //   this.options);
    //   }, 600000);
    // this.options = {
    //   enableHighAccuracy: false,
    //   timeout: 10800000,
    //   maximumAge: 0,
    // };

    // this.getpolicydata();
  }

  ngAfterViewInit() {
    // Ensure map is initialized after view is fully loaded
    if (this.map) {
      this.setRoutePolyline();
    }
  }

  closePopup1() {
    this.displayLocationModal = "none";
  }
  closePopup() {
    this.displayLocationModal = "none";
  }
  openModel() {
    this.submit = true;
    this.popupTitle = "CONFIRM YOUR LOCATION";
    this.displayLocationModal = "block";
  }

  // onInputChange(query: string) {
  //   this.searchAddresses(query);
  // }
  // searchAddresses(query: string) {
  //   if (!query) {
  //     this.addresses = [];
  //     return;
  //   }
  //   // Use the Google Maps Places API to fetch addresses based on the search query
  //   const service = new google.maps.places.AutocompleteService();
  //   service.getPlacePredictions({ input: query }, (predictions) => {
  //     this.addresses = predictions.map((prediction) => prediction.description);
  //     this.showAddressList = true;
  //   });
  // }

  // setSearchAddress(location: string) {
  //   //console.log("setSearchAddress--> ", location);
  //   $(".searchInput").val(location);
  //   this.showAddressList = false;
  // }
  getLocation() {
    // console.log("getLocation");
    if (navigator.geolocation.watchPosition) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.currentLat = position.coords.latitude;
          this.currentLong = position.coords.longitude;

          // console.log("Lat", this.currentLat, "Long", this.currentLong);
          // return false;
          // console.log(this.currentLat, this.currentLong);
          this.checkCustomerLocation();
        },
        (error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: error.message,
          });
        }
      );
    } else {
      console.warn("Geolocation is not supported by this browser.");
    }
  }

  checkCustomerLocation() {
    let payload = {
      job_id: this.encrytJobId,
      latsource: this.currentLat,
      langsource: this.currentLong,
    };
    this.loaderActive = true;
    this.commonService
      .checkCostumerAddressStatusNew(payload)
      .subscribe((response: any) => {
        this.loaderActive = false;
        this.checkCustomerAdd = response;
        sessionStorage.setItem("linktime", this.checkCustomerAdd.link_time);
        this.expiryData = new Date(this.checkCustomerAdd.result?.created_at);
        this.currentDateTime = new Date(this.currentDateTime);

        if (
          this.checkCustomerAdd.status == false ||
          this.response_data?.redirect_url
        ) {
          Swal.fire({ icon: "error", title: "Link Expired." });
          setTimeout(() => {
            window.location.href =
              this.base_url + this.checkCustomerAdd.redirect_url;
          }, 1000); // 3000 milliseconds = 3 seconds
        }

        if (this.checkCustomerAdd.expire == false) {
          Swal.fire({ icon: "error", title: "Link Expired." });
          setTimeout(() => {
            window.location.href = this.base_url + "/expire-page";
          }, 1000); // 3000 milliseconds = 3 seconds
        }

        if (
          this.checkCustomerAdd.status == true &&
          this.expiryData < this.currentDateTime
        ) {
          Swal.fire({
            icon: "error",
            title: "Link Expired.",
          });

          setTimeout(() => {
            window.location.href = this.base_url + "/expire-page";
          }, 1000); // 3000 milliseconds = 3 seconds
        }

        // console.log(this.expiryData, this.currentDateTime);

        if (
          this.checkCustomerAdd.customer_status == false &&
          this.checkCustomerAdd.status == true
        ) {
          localStorage.setItem(
            "customerInfo",
            JSON.stringify(this.checkCustomerAdd.result)
          );
          let values = {
            customer_addrss: this.checkCustomerAdd.result?.cust_curr_addr,
            city: this.checkCustomerAdd.result?.state,
          };
          this.patchValueinfo(values);
          this.openModel();
        }

        this.customerName = this.checkCustomerAdd.result?.customer_name;
        this.customerMobileNo =
          this.checkCustomerAdd.result?.customer_mobile_no;
        this.customerMaskedMobileNo =
          this.checkCustomerAdd.result?.customer_mobile_no_encrypt;
        this.displayMobileNo = this.customerMobileNo;

        this.registrationNo =
          this.checkCustomerAdd.result?.vehicle_registration_no;
        this.vendor_reach_status =
          this.checkCustomerAdd.result?.vendor_reach_status;

        if (
          this.checkCustomerAdd.customer_status == true &&
          this.checkCustomerAdd.status == true
        ) {
          this.custCurrAddr = this.checkCustomerAdd.result?.cust_curr_addr;
          // this.customerName=this.checkCustomerAdd.result?.customer_name;
          // this.custCurrAddr=this.checkCustomerAdd.result?.cust_curr_addr;
          // this.customerMobileNo=this.checkCustomerAdd.result?.customer_mobile_no;
          // this.registrationNo=this.checkCustomerAdd.result?.vehicle_registration_no;
          this.getWorkshopDetails();
        }

        if (this.checkCustomerAdd.status == false) {
          Swal.fire({
            icon: "error",
            title: this.checkCustomerAdd.message,
          });
        }
      });
  }
  patchValueinfo(values) {
    this.customerLocationForm.patchValue({
      customer_addrss: values.customer_addrss,
      city: values.city,
    });
  }
  getCustomerLocation() {
    this.loaderActive = true;
    // this.getLocation();
    // console.log(this.currentLat, this.currentLong);

    let payload = {
      task_id: this.job_id,
      latsource: this.currentLat,
      langsource: this.currentLong,
      cust_addr: "",
    };
    this.commonService
      .updateCostumerAddressData(payload)
      .subscribe((response: any) => {
        this.loaderActive = false;
        this.latlongInfo = response.result;
        this.workshopInfo = response.workshop_details;

        // console.log('Rahul cust_curr_addr',this.latlongInfo.cust_curr_addr);
        // return
        // if(this.latlongInfo.cust_curr_addr=='' || this.latlongInfo.cust_curr_addr==null){
        //     this.openModel();
        //  }else{
        //   this.getWorkshopDetails();
        //  }

        localStorage.setItem("customerInfo", JSON.stringify(this.latlongInfo));

        this.cust_Details = localStorage.getItem("customerInfo");
        this.cust_Info = JSON.parse(this.cust_Details);

        let values = {
          customer_addrss: this.cust_Info?.cust_curr_addr,
          city: this.cust_Info?.state,
        };
        this.patchValueinfo(values);
      });

    // this.commonService
    //   .getCostumerAddressDetails(payload)
    //   .subscribe((response: any) => {
    //     this.loaderActive = false;
    //     this.vendor_id = response.result;
    //   });
  }

  getWorkshopDetails() {
    this.loaderActive = true;
    let payload = {
      task_id: this.encrytJobId,
      // vendor_id: this.vendor_id,
    };

    this.commonService
      .getLatLongDataByWorkshopNew(payload)
      .subscribe((response: any) => {
        this.result = response;
        localStorage.setItem(
          "workshopData",
          JSON.stringify(this.result.result)
        );
        // console.log("result", this.result.result);

        if (this.result.workshop_status) {
          this.costumer_location = this.result.result.customer_location;
          this.vendor_track_location = this.result.result.vendor_track_location;
          // console.log("vendor_track_location", this.vendor_track_location);

          this.jobidval = this.result.result.customer_location.jobid;
          this.vendorDetails = this.result.result.vendor_track_location;
          this.workshopLocation = this.result.result.workshop_location;
          this.task_id = this.get_task_id;
          //this.customer_name = this.customer_location.customer_name;
          this.vendor_assigned_status = this.result.vendor_asigned_status;

          this.customerLat = this.costumer_location.lat;
          this.customerLong = this.costumer_location.longi;

          this.vendor_lat = this.vendor_track_location?.lat;
          this.vendor_longi = this.vendor_track_location?.longi;
          // console.log("vendor_lat", this.vendor_lat);
          // console.log("vendor_longi", this.vendor_longi);
          // this.vendor_lat = this.policyData.vendor_lat;
          // this.vendor_longi = this.policyData.vendor_longi;

          this.loaderActive = false;
          if (this.vendor_assigned_status == false) {
            var waypoint = {
              latitude: this.costumer_location.lat,
              longitude: this.costumer_location.longi,
            };

            // console.log("waypoint--1", waypoint);
            // console.log("custumer_location", waypoint);
            var origin = {
              latitude: this.vendor_track_location["lat"],
              longitude: this.vendor_track_location["longi"],
            };
            // console.log("vendorlocation", origin);
            var destination = {
              latitude: this.workshopLocation["lat"],
              longitude: this.workshopLocation["longi"],
            };
            // console.log("workshoplocation", destination);
          }
          this.setRoutePolyline();
        }
        // console.log(this.workshop_details);
      });
  }
  saveAddress() {
    this.loaderActive = true;
    this.displayLocationModal = "none";
    this.cust_Details = localStorage.getItem("customerInfo");
    this.cust_Info = JSON.parse(this.cust_Details);
    let addressData = {
      task_id: this.encrytJobId,
      latsource: this.currentLat,
      langsource: this.currentLong,
      cust_addr: this.cust_Info?.cust_curr_addr,
      city: this.cust_Info?.cust_pincode,
      cust_curr_addr: "",
    };

    this.commonService
      .updateCostumerAddress(addressData)
      .subscribe((response: any) => {
        this.loaderActive = false;
        this.response_data = response;
        this.result = response.result;

        if (this.response_data.status == "true") {
          this.customerName = this.result?.customer_name;
          this.custCurrAddr = this.result?.cust_curr_addr;
          this.customerMobileNo = this.result?.customer_mobile_no;
          Swal.fire(this.result?.message);
        } else {
          if (this.response_data?.redirect_url) {
            setTimeout(() => {
              window.location.href =
                this.base_url + this.response_data.redirect_url;
            }, 1000);
          }
          Swal.fire(this.response_data?.message);
        }

        this.getWorkshopDetails();
      });
  }
  async setRoutePolyline() {
    let latlongdata = localStorage.getItem("workshopData");
    this.latlong = JSON.parse(latlongdata);
    // console.log("latlong", this.latlong);

    this.workshopInfo = this.latlong?.workshop_location;
    // console.log("workshopInfo", this.workshopInfo);
    // let c_name=this.workshopInfo.contact_person;
    // console.log(c_name);

    // let start = {
    //   lat: this.currentLat,
    //   lng: this.currentLong,
    // };

    let start = {
      lat: this.customerLat,
      lng: this.customerLong,
    };

    // let start = {
    //   lat: '19.6941966',
    //   lng: '72.7301169',
    // };

    // console.log("start in", start);
    let end = {
      lat: this.workshopInfo.lat,
      lng: this.workshopInfo.longi,
    };

    // let waypoint =
    // {
    //   lat: this.latlong.vendor_location[0].lat,
    //   long: this.latlong.vendor_location[0].longi,
    // };

    let waypoint = {
      lat: this.vendor_lat,
      long: this.vendor_longi,
    };
    // console.log('start',start,"waypoint",waypoint,"end",end);

    let origin_lat = Number(start.lat);
    let origin_lng = Number(start.lng);
    let origin = {
      lat: origin_lat,
      lng: origin_lng,
    };
    console.log("dorigin", origin);
    let end_lat = Number(end.lat);
    let end_lng = Number(end.lng);
    let destination = { lat: end_lat, lng: end_lng };

    let waypoint_lat = Number(waypoint.lat);
    let waypoint_lng = Number(waypoint.long);
    let waypointt = { lat: waypoint_lat, lng: waypoint_lng };
    console.log("waypoint",waypointt);
   const vendorContent = document.createElement("div");
   vendorContent.innerHTML = `<img src="./assets/front/img/vendar-icon.png" style="width: 40px; height: 40px;" />`;
   const customerContent = document.createElement("div");
   customerContent.innerHTML = `<img src="./assets/front/img/customer.png" style="width: 40px; height: 40px;" />`;
   const workshopContent = document.createElement("div");
   workshopContent.innerHTML = `<img src="./assets/front/img/workshop.jpg" style="width: 40px; height: 40px;" />`;

   var markerStart = new google.maps.marker.AdvancedMarkerElement({
     position: origin,
     map: this.map,
     content: customerContent,
   });

   var destinationMarker = new google.maps.marker.AdvancedMarkerElement({
     position: destination,
     map: this.map,
     content: workshopContent,
   });

   var waypointMarker = new google.maps.marker.AdvancedMarkerElement({
     position: waypointt,
     map: this.map,
     content: vendorContent,
   });
   console.log("ddestination", destination);

   // var markerStart = new google.maps.Marker({
   //   position: origin,
   //   title: "Customer Location",
   //   icon: {
   //     url: "./assets/front/img/customer.png",
   //     scaledSize: new google.maps.Size(30, 30),
   //   },
   //   map: this.map,
   // });

   // var destinationMarker = new google.maps.Marker({
   //   position: destination,
   //   title: "Workshop Location",
   //   icon: {
   //     url: "./assets/front/img/workshop.jpg",
   //     scaledSize: new google.maps.Size(30, 30),
   //   },
   //   map: this.map,
   // });

   // var waypointMarker = new google.maps.Marker({
   //   position: waypointt,
   //   title: "Vendor Location",
   //   icon: {
   //     url: "./assets/front/img/vendar-icon.png",
   //     scaledSize: new google.maps.Size(30, 30),
   //   },
   //   map: this.map,
   // });

   let request: any = {
     origin: origin,
     destination: destination,
     waypoints: [{ location: waypointt }],
     travelMode: google.maps.TravelMode.DRIVING,
     avoidTolls: true,
   };

   this.ds.route(request, (response, status) => {
     this.dr.setOptions({
       suppressPolylines: false,
       suppressMarkers: true,
       map: this.map,
       draggable: false,
     });

     if (status == google.maps.DirectionsStatus.OK) {
       this.dr.setDirections(response);
       this.dr.setPanel(document.getElementById("directionsList"));

       var directionsData = response.routes[0].legs;

       console.log("directionsData ", directionsData);

       document.getElementById("msg").innerHTML +=
         " Driving distance is " +
         directionsData[0].distance.text +
         " (" +
         directionsData[0].duration.text +
         ").";
     }
   });

    // this.ds.route(request, (response, status) => {

    //   if (status !== "OK") {
    //     window.alert("Directions request failed due to " + status);
    //     return;
    //   } else {
    //     // this.dr.setDirections(response);

    //     if (!directionsData) {
    //       window.alert("Directions request failed");
    //       return;
    //     } else {
    //       this.dr.setDirections(response);

    //     }
    //   }
    // });
  }

  mapInitializer() {
    const coordinates = new google.maps.LatLng(this.lat1, this.lng2);
    const mapOptions: google.maps.MapOptions = {
      center: coordinates,
      zoom: 15,
      scrollwheel: true,
      disableDefaultUI: true,
      mapId: "5f9700d7ed39be09",
    };

    this.map = new google.maps.Map(this.gmap.nativeElement, mapOptions);
    this.ds = new google.maps.DirectionsService();
    this.dr = new google.maps.DirectionsRenderer();

    this.dr.setMap(this.map);

    this.map = new google.maps.Map(this.gmap.nativeElement, mapOptions);
    // console.log("Map initialized:", this.map);
    // this.setRoutePolyline();
  }

  toggleMaskedData(customerMobileNo): void {
    this.isEyeIconVisible = !this.isEyeIconVisible;
    this.displayMobileNo = this.isEyeIconVisible
      ? this.customerMobileNo
      : this.functiondivideString(this.customerMaskedMobileNo);
    //   const mobileNO=   this.functiondivideString(this.customerMaskedMobileNo);
    // if (this.isEyeIconVisible) {
    //   return mobileNO;
    // } else {
    //   return this.customerMobileNo;
    // }
  }

  functiondivideString(inputStr) {
    // console.log("inputStr", inputStr);
    if (inputStr) {
      const firstPart = inputStr.slice(0, 5);
      // console.log("firstPart:", firstPart);

      // Take the next 48 characters
      const secondPart = inputStr.slice(5, 53);
      // console.log("secondPart:", secondPart);
      const secretkey = secondPart.slice(0, 32);
      const iv = secondPart.slice(32);
      // console.log("secretkey", secretkey);
      // console.log("iv", iv);

      // Take the remaining string after the first 5 and 48 characters
      const thirdPart = inputStr.slice(53);
      // console.log("thirdPart:", thirdPart);
      let concatenatedfirstthird = firstPart + thirdPart;
      // console.log("concatfirstthird", firstPart + thirdPart);
      let encryptedDetails = this.encryptionService.randonmDecrypt(
        secretkey,
        iv,
        concatenatedfirstthird
      );

      return encryptedDetails;
    }
  }

  // getAddressDetails(event: any) {
  //   this.location = this.customerLocationForm.value.location;
  //   this.setaddress = event.target.value;
  //   (<HTMLInputElement>document.getElementById("customer_addrss")).value =
  //     this.setaddress;
  //   if (this.setaddress == "") {
  //     this.showAddressList = false;
  //     if (this.setaddress == "") {
  //       this.showAddressList = false;
  //     }
  //   }
  // }
}
