import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { AuthService } from './auth.service'; // Adjust the path as necessary
//import { AuthService } from './auth.service'; // Adjust the path as necessary
import { EncryptionService } from "../services/encryption.service";

import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
@Injectable({
  providedIn: "root",
})


export class CommonService {
  base_url = environment.baseUrl;
  constructor(private httpClient: HttpClient,   private authService: AuthService, public router: Router, private encryptionService: EncryptionService) {}

  // getCssLogoByUrl(sendData){
  //   return this.httpClient.post(this.base_url+'getCssLogoByUrl',sendData);
  // }

  resetPassword(sendData) {
    return this.httpClient.post(this.base_url + "reset-password", sendData);
  }

  userInfo() {
    let loggedInUser = {
      loggedInUserId: sessionStorage.getItem("loginUserId"),
      rsa_id: this.encryptionService.functiondivideString(sessionStorage.getItem("admin_status")),
      loginUserRoleId: this.encryptionService.functiondivideString(sessionStorage.getItem("loginUserRoleId")),
    };
    return loggedInUser;
  }

  submitLoginFormData(sendData) {
    return this.httpClient.post(this.base_url + "admin-login", sendData);
  }

  getsidebarmenus(sendData) {
    return this.httpClient.post(
      this.base_url + "admin/getsidebarmenus",
      sendData
    );
  }
  GetMobileDetails(sendData) {
    return this.httpClient.post(this.base_url + "search-mobile", sendData);
  }

  SendOTP(sendData) {
    return this.httpClient.post(this.base_url + "send-otp", sendData);
  }
  verifyOTP(sendData) {
    return this.httpClient.post(this.base_url + "verify-otp", sendData);
  }

  GetPolicyDetails(sendData) {
    return this.httpClient.post(this.base_url + "search-policies", sendData);
  }

  // GetpolicyCount(sendData) {
  //   return this.httpClient.post(this.base_url + "dashboared", sendData);
  // }

  GetModelType(sendData) {
    return this.httpClient.post(this.base_url + "getmodel-list", sendData);
  }

  GeLocationType(sendData) {
    return this.httpClient.post(this.base_url + "get-location-type", sendData);
  }

  GetStateData(sendData) {
    return this.httpClient.post(this.base_url + "get-state-data", sendData);
  }

  GetCityStateData(sendData) {
    return this.httpClient.post(
      this.base_url + "get-city-state-data",
      sendData
    );
  }

  GetServiceType(sendData) {
    return this.httpClient.post(
      this.base_url + "get-questionaries-data",
      sendData
    );
  }

  GetBreakdownReason(sendData) {
    return this.httpClient.post(
      this.base_url + "get-anslist-by-question",
      sendData
    );
  }
  GetPolicyCount(sendData) {
    return this.httpClient.post(
      this.base_url + "get-dashboard-countsbytype",
      sendData
    );
  }

  GetScheduleService(sendData) {
    return this.httpClient.post(this.base_url + "set-schedule", sendData);
  }
  GetActivateNowData(sendData) {
    return this.httpClient.post(
      this.base_url + "set-question-answer-details",
      sendData
    );
  }

  GetActivateNowDataNew(sendData) {
    return this.httpClient.post(
      this.base_url + "set-question-answer-details-new",
      sendData
    );
  }

  // customer api call...
  UpdateScheduleData(sendData) {
    return this.httpClient.post(
      this.base_url + "set-schedule-update",
      sendData
    );
  }

  UpdateScheduleAdditionalData(sendData) {
    return this.httpClient.post(
      this.base_url + "set-schedule-update-additional",
      sendData
    );
  }

  UpdateActivateNow(sendData) {
    return this.httpClient.post(
      this.base_url + "set-question-answer-details-update",
      sendData
    );
  }

  UpdateAdditionAssistenceActivateNow(sendData) {
    return this.httpClient.post(
      this.base_url + "set-addtional-assistence-question-answer-update",
      sendData
    );
  }

  getPolicyDetails(sendData) {
    return this.httpClient.post(
      this.base_url + "get-policy-details-by-taskid",
      sendData
    );
  }
  GetPolicyDetailsByid(sendData) {
    return this.httpClient.post(
      this.base_url + "get-policy-by-policyid",
      sendData
    );
  }
  GetCustomerOnboard(sendData) {
    return this.httpClient.post(
      this.base_url + "get-customer-onboard-details",
      sendData
    );
  }

  GetCustomerDecline(sendData) {
    return this.httpClient.post(
      this.base_url + "get-vender-decliend-details",
      sendData
    );
  }

  GetOpenCases(sendData) {
    return this.httpClient.post(this.base_url + "get-open-claims", sendData);
  }
  GetClaimHistory(sendData) {
    return this.httpClient.post(this.base_url + "get-claim-history", sendData);
  }
  GetCustomerAllCases(sendData) {
    return this.httpClient.post(
      this.base_url + "get-all-cases-details",
      sendData
    );
  }
  GetCustomerTodaysClaims(sendData) {
    return this.httpClient.post(
      this.base_url + "get-all-today-cases-details",
      sendData
    );
  }

  GetCustomerTodaysClaimsEscalation(sendData) {
    return this.httpClient.post(
      this.base_url + "get-all-today-cases-details-escalation",
      sendData
    );
  }

  GetCustomerAssigned(sendData) {
    return this.httpClient.post(
      this.base_url + "get-vendar-assigned-details",
      sendData
    );
  }

  GetCustomerTaskComp(sendData) {
    return this.httpClient.post(
      this.base_url + "get-job-completed-details",
      sendData
    );
  }

  GetSelectUser(sendData) {
    return this.httpClient.post(this.base_url + "get-users-data", sendData);
  }

  GetChildUser(sendData) {
    return this.httpClient.post(
      this.base_url + "get-child-users-data",
      sendData
    );
  }

  GetStatusHistory(sendData) {
    return this.httpClient.post(this.base_url + "get-status-data", sendData);
  }

  getCommentHistory(sendData) {
    return this.httpClient.post(this.base_url + "get-agent-comments", sendData);
  }

  getMobileHistory(sendData) {
    return this.httpClient.post(this.base_url + "get-mobile-number", sendData);
  }
  getJobHistory(sendData) {
    return this.httpClient.post(
      this.base_url + "get-task-job-history",
      sendData
    );
  }

  addagentComment(sendData) {
    return this.httpClient.post(this.base_url + "add-comment", sendData);
  }

  GetWorkshopAssign(sendData) {
    return this.httpClient.post(
      this.base_url + "get-workshop-assign-details",
      sendData
    );
  }
  GetVendorAssign(sendData) {
    return this.httpClient.post(
      this.base_url + "get-vendor-assign-details",
      sendData
    );
  }
  GetUserAssign(sendData) {
    return this.httpClient.post(this.base_url + "set-assign-user", sendData);
  }

  GetUserData(sendData) {
    return this.httpClient.post(
      this.base_url + "get-assign-users-name",
      sendData
    );
  }

  GetCancelAssign(sendData) {
    return this.httpClient.post(
      this.base_url + "get-assign-user-cancle",
      sendData
    );
  }

  updateAddressDetails(sendData) {
    return this.httpClient.post(
      this.base_url + "set-assign-user-latlong",
      sendData
    );
  }
  // getCostumerAddressDetails(sendData) {
  //   return this.httpClient.post(
  //     this.base_url + "set-assign-user-latlong",
  //     sendData
  //   );
  // }

  updateTaskStatus(sendData) {
    return this.httpClient.post(
      this.base_url + "set-update-admin-task-status",
      sendData
    );
  }

  uploadAudioFile(sendData) {
    return this.httpClient.post(this.base_url + "set-upload-file", sendData);
  }

  getWorkshopDetails(sendData) {
    return this.httpClient.post(
      this.base_url + "get-all-latlong-with-customer",
      sendData
    );
  }

  getWorkshopDetailsNew(sendData) {
    return this.httpClient.post(
      this.base_url + "get-all-latlong-with-customer-by-job-new",
      sendData
    );
  }

  getLatLongDetailsByWorkshop(sendData) {
    return this.httpClient.post(
      this.base_url + "get-all-latlong-with-customer-by-job",
      sendData
    );
  }
  getLatLongDataByWorkshop(sendData) {
    return this.httpClient.post(
      this.base_url + "get-all-latlong-with-customer-details",
      sendData
    );
  }
  getLatLongDataByWorkshopNew(sendData) {
    return this.httpClient.post(
      this.base_url + "get-all-latlong-with-customer-details-new",
      sendData
    );
  }
  getWorkshopId(sendData) {
    return this.httpClient.post(
      this.base_url + "set-workshop-to-task",
      sendData
    );
  }
  getVendorUpdateLocation(sendData) {
    return this.httpClient.post(this.base_url + "set-location-task", sendData);
  }
  getSendLink(sendData) {
    return this.httpClient.post(this.base_url + "set-link-vendar", sendData);
  }
  getReachStatus(sendData) {
    return this.httpClient.post(
      this.base_url + "set-update-vendar-reach-status",
      sendData
    );
  }
  getTasktatus(sendData) {
    return this.httpClient.post(
      this.base_url + "set-update-vendar-task-completed-status",
      sendData
    );
  }
  getUpdatedLocation(sendData) {
    return this.httpClient.post(
      this.base_url + "set-vendar-latest-latlong",
      sendData
    );
  }
  updateVendorReachStatus(sendData) {
    return this.httpClient.post(
      this.base_url + "set-update-vendar-reached-time",
      sendData
    );
  }

  updateVendorTaskStatus(sendData) {
    return this.httpClient.post(
      this.base_url + "set-update-vendar-reach-status-task-by-job",
      sendData
    );
  }
  getTemporaryVendorTrack(sendData) {
    return this.httpClient.post(
      this.base_url + "get-tempory-vendor-track",
      sendData
    );
  }
  getVendorDistanceTime(sendData) {
    return this.httpClient.post(
      this.base_url + "set-vendar-distance-time",
      sendData
    );
  }

  getVendorLinkDistanceTime(sendData) {
    return this.httpClient.post(
      this.base_url + "set-vendar-link-distance-time",
      sendData
    );
  }

  submitVendorLatLong(payload) {
    return this.httpClient.post(
      this.base_url + "submit-vendar-latlong",
      payload
    );
  }
  updateCostumerAddress(payload) {
    return this.httpClient.post(
      this.base_url + "set-update-customer-address",
      payload
    );
  }
  updateCostumerAddressData(payload) {
    return this.httpClient.post(
      this.base_url + "set-assign-user-latlong-by-job",
      payload
    );
  }
  //menu

  getmenus() {
    return this.httpClient.get(this.base_url + "admin/getmenulist");
  }
  getMenuDataById(sendData) {
    return this.httpClient.post(
      this.base_url + "admin/getMenuDataById",
      sendData
    );
  }

  changeStatuseByMenuId(sendData) {
    return this.httpClient.post(
      this.base_url + "admin/changeStatuseByMenuId",
      sendData
    );
  }

  MenuUpdate(sendData) {
    return this.httpClient.post(this.base_url + "admin/MenuUpdate", sendData);
  }

  getParentMenu() {
    return this.httpClient.get(this.base_url + "admin/getParentMenu");
  }

  //menu

  //Role

  getUserAllRoleDataById(sendData) {
    return this.httpClient.post(
      this.base_url + "admin/getUserAllRoleDataById",
      sendData
    );
  }

  getUserRoleDataById(sendData) {
    return this.httpClient.post(
      this.base_url + "admin/getUserRoleDataById",
      sendData
    );
  }

  userRoleDataUpdate(sendData) {
    return this.httpClient.post(
      this.base_url + "admin/userRoleDataUpdate",
      sendData
    );
  }

  getUserDataById(sendData) {
    return this.httpClient.post(
      this.base_url + "admin/getUserDataById",
      sendData
    );
  }

  userDataUpdate(sendData) {
    return this.httpClient.post(
      this.base_url + "admin/userDataUpdate",
      sendData
    );
  }

  getStatusData() {
    return this.httpClient.get(this.base_url + "admin/getstatusdata");
  }

  getAdminUserTypeSelection(sendData) {
    return this.httpClient.post(
      this.base_url + "admin/getAdminUserTypeSelection",
      sendData
    );
  }

  changeRoleAccess(sendData) {
    return this.httpClient.post(
      this.base_url + "admin/changeRoleAccess",
      sendData
    );
  }

  changeStatusByMakeId(sendData) {
    return this.httpClient.post(
      this.base_url + "admin/changestatusbymakeid",
      sendData
    );
  }

  changeStatusActiveInactive(sendData) {
    return this.httpClient.post(
      this.base_url + "admin/changeStatusActiveInactive",
      sendData
    );
  }

  changeUserDutyManager(sendData) {
    return this.httpClient.post(
      this.base_url + "changeUserDutyManager",
      sendData
    );
  }

  getMenusPrivilegeUser(sendData) {
    return this.httpClient.post(
      this.base_url + "admin/getMenusPrivilegeUser",
      sendData
    );
  }

  submitMenuPrivileges(sendData) {
    return this.httpClient.post(
      this.base_url + "admin/submitMenuPrivileges",
      sendData
    );
  }

  //Role

  //user
  getHierarchyLevels() {
    return this.httpClient.get(this.base_url + "admin/getHierarchyLevels");
  }

  getBusinessLevels(sendData) {
    return this.httpClient.post(
      this.base_url + "admin/getBusinessLevels",
      sendData
    );
  }

  getAdminRoleSelection(sendData) {
    return this.httpClient.post(
      this.base_url + "admin/getAdminRoleSelection",
      sendData
    );
  }

  getAdminDepartmentTypeSelection(sendData) {
    return this.httpClient.post(
      this.base_url + "admin/getAdminDepartmentTypeSelection",
      sendData
    );
  }
  //user
  getVendorIdByTaskId(payload) {
    return this.httpClient.post(this.base_url + "get-vendar-by-task", payload);
  }

  getVendorIdByTaskIdByJob(payload) {
    return this.httpClient.post(
      this.base_url + "get-vendar-by-task-job",
      payload
    );
  }

  getPolicyDataByTask(payload) {
    return this.httpClient.post(
      this.base_url + "get-vendor-latlong-data",
      payload
    );
  }

  getIcAllData() {
    return this.httpClient.get(this.base_url + "admin/getIcData");
  }

  getParentUserData() {
    return this.httpClient.get(this.base_url + "admin/getParentUserData");
  }

  getRoleData() {
    return this.httpClient.get(this.base_url + "admin/getRoleData");
  }

  GetAnalysisFeedbackCancelComment(sendData) {
    return this.httpClient.post(
      this.base_url + "get-analisis-feedback-cancel-comment",
      sendData
    );
  }

  GetStateMappingData(sendData) {
    return this.httpClient.post(
      this.base_url + "get-state-mapping-data",
      sendData
    );
  }

  GetPincodeStateCityMappingData(sendData) {
    return this.httpClient.post(
      this.base_url + "get-pincode-state-city-mapping-data",
      sendData
    );
  }
  //MIS

  GetRSAReportData(sendData) {
    return this.httpClient.post(
      this.base_url + "get-rsa-jobs-details",
      sendData
    );
  }

  GetSystemReachTime(sendData) {
    // let  sendData = '';
    return this.httpClient.post(
      this.base_url + "get-system-reachtime-details-new",
      sendData
    );
  }

  GetSystemDashboard(sendData) {
    return this.httpClient.post(
      this.base_url + "get-system-dashboard",
      sendData
    );
  }

  GetSLA(sendData) {
    return this.httpClient.post(this.base_url + "get-sla-data", sendData);
  }

  GetSLAData(sendData) {
    return this.httpClient.post(this.base_url + "get-sla-new-data", sendData);
  }

  GetCustomerPerformanceData(sendData) {
    return this.httpClient.post(
      this.base_url + "get-customer-performanc-data",
      sendData
    );
  }

  GetSystemKeySla(sendData) {
    return this.httpClient.post(
      this.base_url + "get-key-city-sla-details",
      sendData
    );
  }
  GetKeyCityReachTimeAvg(sendData) {
    return this.httpClient.post(
      this.base_url + "get-key-city-reach-time-avg-details",
      sendData
    );
  }

  exportRsaJobsDetail(sendData) {
    return this.httpClient.post(
      this.base_url + "downloadExcellAllRsaJobsDetailsNew",
      sendData
    );
  }

  exportCustomerJourneyDetails(sendData) {
    return this.httpClient.post(
      this.base_url + "downloadExcellCustomerJourney",
      sendData
    );
  }

  exportGetSystemDashboard(sendData) {
    return this.httpClient.post(
      this.base_url + "downloadExcellAllRsaJobsDetailsNew",
      sendData
    );
  }
  exportGetSystemkeycity(sendData) {
    return this.httpClient.post(
      this.base_url + "downloadExcellSystemKey",
      sendData
    );
  }
  exportkeycity(sendData) {
    return this.httpClient.post(this.base_url + "getKeyCitiesSLA", sendData);
  }

  downloadAllCasesDetails(sendData) {
    return this.httpClient.post(
      this.base_url + "download-all-cases-details",
      sendData
    );
  }

  downloadKeyCitiesSLA(sendData) {
    return this.httpClient.post(
      this.base_url + "getKeyCitiesReachTimeSLA",
      sendData
    );
  }

  downloadDayWiseSLA(sendData) {
    return this.httpClient.post(this.base_url + "downloadDayWiseSLA", sendData);
  }

  GetRsaStatusData(sendData) {
    return this.httpClient.post(
      this.base_url + "get-rsa-status-data",
      sendData
    );
  }

  GetRsaIcData(sendData) {
    return this.httpClient.post(this.base_url + "get-rsa-ic-data", sendData);
  }

  checkCostumerAddressStatus(payload) {
    return this.httpClient.post(
      this.base_url + "check-customer-address-status",
      payload
    );
  }

  checkCostumerAddressStatusNew(payload) {
    return this.httpClient.post(
      this.base_url + "check-customer-address-status-new",
      payload
    );
  }

  updateTaskFromHold(sendData) {
    return this.httpClient.post(
      this.base_url + "update-task-from-hold",
      sendData
    );
  }

  uploadCase(sendData) {
    return this.httpClient.post(
      this.base_url + "assistance-partner-upload-case",
      sendData
    );
  }

  taskPickCase(sendData) {
    return this.httpClient.post(this.base_url + "taskPickCase", sendData);
  }

  uploadVideoByVendor(sendData) {
    return this.httpClient.post(
      this.base_url + "upload-video-by-vendor",
      sendData
    );
  }

  getSendUniversalLink(sendData) {
    return this.httpClient.post(
      this.base_url + "set-upload-video-link-vendor",
      sendData
    );
  }

  updateRequestResponceJsonDetails(sendData) {
    return this.httpClient.post(
      this.base_url + "add-request-responce-json",
      sendData
    );
  }
  GetSmsStepDetails(sendData) {
    return this.httpClient.post(this.base_url + "sms-step-details", sendData);
  }

  updateTaskSMSStatus(sendData) {
    return this.httpClient.post(
      this.base_url + "update-task-sms-status",
      sendData
    );
  }

  updateTaskFeedbackStatus(sendData) {
    return this.httpClient.post(
      this.base_url + "update-task-feedback-status",
      sendData
    );
  }

  getCustVendorDataByTask(payload) {
    return this.httpClient.post(
      this.base_url + "get-cust-vendor-data",
      payload
    );
  }

  uploadVendorVideoUsingLink(sendData) {
    return this.httpClient.post(
      this.base_url + "upload-vendor-video-using-link",
      sendData
    );
  }

  sendRegistrationLinkToCustomer(sendData) {
    return this.httpClient.post(
      this.base_url + "send-registration-link-customer",
      sendData
    );
  }

  uploadEuroTaskCloseCase(sendData) {
    return this.httpClient.post(
      this.base_url + "euro-task-close-upload-case",
      sendData
    );
  }
  feedback(sendData) {
    return this.httpClient.post(this.base_url + "submit-feedback", sendData, {
    });
  } // Missing closing brace here

  getPolicyDetailsNew(sendData) {
    return this.httpClient.post(
      this.base_url + "get-policy-details-by-taskid-new",
      sendData
    );
  }

  verifyLoginOTP(sendData) {
    return this.httpClient.post(this.base_url + "login-verify-otp", sendData);
  }

  
  sendLoginOTP(sendData) {
    return this.httpClient.post(this.base_url + "login-send-otp", sendData);
  }

  checkFeedbackExpiry(sendData) {
    return this.httpClient.post(
      this.base_url + "check-feedback-expiry",
      sendData
    );
  }
} 
  


  // calculateDistance(origin: string, destination: string) {
  //   const url = `https://maps.googleapis.com/maps/api/distancematrix/json?origins=${origin}&destinations=${destination}&key=${this.apiKey}`;
  //   return this.http.get<any>(url).pipe(map(data => data.rows[0].elements[0].distance.value));
  // }

