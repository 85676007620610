import { NgModule } from '@angular/core';
import { APP_BASE_HREF, Location } from "@angular/common";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule, HashLocationStrategy, LocationStrategy, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { ChartsModule } from 'ng2-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WebcamModule } from 'ngx-webcam';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { BnNgIdleService } from "bn-ng-idle";
import { VendorlocationComponent } from './admin/vendorlocation/vendorlocation.component';
import { CustomerLocationDetailsComponent } from './admin/customer-location-details/customer-location-details.component';
import { LoaderComponent } from './loader/loader.component';
import { UniversallinkComponent } from './admin/universallink/universallink.component';
import { NotAllowedComponent } from './not-allowed/not-allowed.component';
import { ExpirePageComponent } from './expire-page/expire-page.component';

import { AuthService } from './admin/services/auth.service';
import { AdminInterceptor } from './admin/share/admin.interceptor';
import { TrimSpacesDirective } from './admin/shared/directives/trim-spaces.directive';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    LoaderComponent,
    AccessDeniedComponent,
    DashboardComponent,
    VendorlocationComponent,
    CustomerLocationDetailsComponent,
    TrimSpacesDirective,
    NotAllowedComponent,
    UniversallinkComponent,
    ExpirePageComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    SimpleNotificationsModule.forRoot(),
    WebcamModule,
    NgbModule,
  ],
  providers: [
    AuthService,
    //{ provide: HTTP_INTERCEPTORS, useClass: FrontInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AdminInterceptor, multi: true },
    // { provide: APP_BASE_HREF, useValue: window['_app_base'] || '/' },
    // { provide: APP_BASE_HREF, useValue: "/newcrm/" || "/" },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    

    BnNgIdleService,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
