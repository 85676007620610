import { Injectable } from '@angular/core';
import * as CryptoJS from "crypto-js";
import { environment } from "../../../environments/environment";


@Injectable({
  providedIn: "root",
})
export class EncryptionService {
  key = environment.encryptionKey;
  Iv = environment.encryptionIVKey;
  constructor() {}

  // Function to encrypt the password
  encryptPassword(password: string): string {
    const key = CryptoJS.enc.Utf8.parse(this.key)
    const iv = CryptoJS.enc.Utf8.parse(this.Iv);

    const encrypted = CryptoJS.AES.encrypt(password, key, { iv: iv }).toString();
    const encoutput = btoa(encrypted);
    return encoutput;
  }

  // Function to decrypt the password
  decryptPassword(encryptedPassword: any): any {
    const key = CryptoJS.enc.Utf8.parse(this.key)
    const iv = CryptoJS.enc.Utf8.parse(this.Iv);

    const decrypted = CryptoJS.AES.decrypt(atob(encryptedPassword), key, { iv: iv });
    const decoutput = decrypted.toString(CryptoJS.enc.Utf8);
    return decoutput;

  }
}

