<div class="content container-fluid">
  <div class="page-header">
    <div class="row align-items-center">
      <div class="col-12">
        <img src="assets/front/img/tvs.png" width="180px">
      </div>
    </div>
  </div>

  <section id="portfolio">
    <div class="row">
      <div class="col-md-12">
        <div class="card shadowbox">
          <div class="card-body pb-0">
            <div class="table-responsive">
              <div class="row">
                <div class="col-lg-12">
                  <!-- <div class="col-md-12">
                    <div *ngIf="result && result.status" class="alert alert-success">
                      {{result.message}}
                    </div>
                    <div *ngIf="result && !result.status " class="alert alert-danger">
                      {{result.message}}
                    </div>
                  </div> -->
                </div>
                <div class="clearfix"></div>
              </div>
  
              <div class="row">
                <div class="col-md-4 col-sm-12">
                  <div class="row">
                    <div *ngIf="policy_details?.reach_status != 'reached' && policy_details?.reach_status != '' && policy_details?.status_id != '14' " class="col-sm-2 col-md-2 col-xs-12 image-container"> 
                      <img src="assets/front/img/vendar-icon.png" alt="IMG" class="image-vendor" />
                    </div>
                    <div *ngIf="policy_details?.reach_status != 'reached' && policy_details?.reach_status != '' && policy_details?.status_id != '14' " class="col-sm-10 col-md-10 col-xs-12">  
                      <h3>Vendor Details :</h3>
                    </div>
                    <div class="col-sm-12 col-md-12 col-xs-12 mt-3">
                      <h5><strong>Vendor Name</strong> : {{ vendor_name || "NA"}}</h5>
                    </div>
                    <div class="col-sm-12 col-md-12 col-xs-12">
                      <h5><strong>Task Start Time</strong> : {{ start_time || "NA"}} </h5>
                    </div>
                    <div class="col-sm-12 col-md-12 col-xs-12">
                      <h5><strong>Reach Time </strong> : {{ reached_time || "NA"}}</h5>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-sm-12">
                  <div class="row">
                    <div *ngIf="policy_details" class="col-sm-2 col-md-2 col-xs-12 image-container"> 
                      <img class="image-customer" alt="IMG" src="assets/front/img/customer.png" />
                    </div>
                    <div *ngIf="policy_details" class="col-sm-10 col-md-10 col-xs-12">  
                      <h3>Customer Details : </h3>
                    </div>

                    <div class="col-sm-12 col-md-12 col-xs-12 mt-3">
                      <h5><strong>Customer Name</strong> : {{ cust_name || "NA"}}</h5>
                    </div>

                    <div class="col-sm-12 col-md-12 col-xs-12">
                      <h5><strong>Mobile No </strong> : {{ cust_mob_no || "NA"}}</h5>
                    </div>
                    <div class="col-sm-12 col-md-12 col-xs-12">
                      <h5><strong>Customer Address</strong> : {{cust_addr || "NA"}}</h5>
                    </div>

                    <div class="col-sm-12 col-md-12 col-xs-12">
                      <h5><strong>Vehicle Registration No.</strong> : {{ cust_reg_no || "NA"}}</h5>
                    </div>

                    <div class="col-sm-12 col-md-12 col-xs-12">
                      <h5><strong>Job No.</strong> : {{ job_id || "NA"}}</h5>
                    </div>

                  </div>

                </div>

                <div class="col-md-4 col-sm-12">
                  <div class="row">
                    <div *ngIf="policy_details" class="col-sm-2 col-md-2 col-xs-12 image-container"> 
                      <img class="image-workshop" alt="IMG" src="assets/front/img/workshop.jpg" />
                    </div>
                    <div *ngIf="policy_details" class="col-sm-10 col-md-10 col-xs-12">  
                      <h3>Workshop Details : </h3>
                    </div>
                    <div class="col-sm-12 col-md-12 col-xs-12 mt-3">
                      <h5><strong>Workshop Code</strong> : {{workshop_code || "NA"}}</h5>
                    </div>
                    <div class="col-sm-12 col-md-12 col-xs-12">
                      <h5><strong>Workshop Name </strong> : {{workshop_contact_person || "NA"}} </h5>
                    </div>
                    <div class="col-sm-12 col-md-12 col-xs-12">
                      <h5> <strong>Mobile No</strong>: {{workshop_mobile_no || "NA"}}</h5>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mt-3 mb-3">

                  <div class="row">

                    <div *ngIf="updateVideoButton" class="col-md-3 col-sm-12">
                      <button class="btn btn-success btn-block"  *ngIf="updateVideoButton" (click)="onUploadVideoDisplay()"><span>Upload Pick Video</span></button>
                    </div>

                    <div *ngIf="viewPickUpVideoUrl" class="col-md-3 col-sm-12">
                      <a *ngIf="viewPickUpVideoUrl" class="btn btn-primary btn-block" [href]="viewPickUpVideoUrl" target="_blank"> View Pick Video</a>
                    </div>

                    <div *ngIf="droppVendorUploadForm" class="col-md-3 col-sm-12">
                      <button class="btn btn-success btn-block"  *ngIf="droppVendorUploadForm"  (click)="onUploadDroppVideoDisplay()"><span>Upload Drop Video</span></button>
                    </div>

                    <div *ngIf="viewDropVideoUrl" class="col-md-3 col-sm-12">
                      <a *ngIf="viewDropVideoUrl" class="btn btn-primary btn-block" [href]="viewDropVideoUrl" target="_blank"> View Drop Video </a>
                    </div>

                  </div>

                </div>

                <div *ngIf="videoMsgShow" class="col-md-12 col-sm-12 mt-3 mb-3">
                  <div *ngIf="videoMsgShow" class="alert alert-danger">
                    <strong>Vendor Not Add For this task ! </strong> Frist you need to update vendor details to show pick up and drop video upload button.
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-center" style="height: 24px;">
          <p style="color:whitesmoke !important ; font-size:small !important;">© 2019. All rights reserved. |
            Designed by
            <a style="color:red !important" href="https://www.indicosmic.com/"
              title="Indicosmic Capital Private Limited"><span>Indicosmic Capital Private Limited</span></a>
          </p>
        </div>
      </div>
    </div>
  </footer>
  
</div>


  
  
  <div class="modal modal-overlaybg" tabindex="1" role="dialog" [ngStyle]="{ display: uploadVideoStatusDisplay }">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Upload 360 View Video</h4>
          <button (click)="onUploadVideoDisplay1()" type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <form [formGroup]="uploadVideoTaskStatusForm" (ngSubmit)="onUploadVideoForVendor()">
          <div class="form-group col-lg-12">
            <div class="form-group">
              <input type="hidden" formControlName="taskId">
              <label>Upload Video<span class="text-danger">*</span></label>
              <input type="file"  formControlName="uploadVideo" id="file" class="form-control" (change)="onFileChange($event)">
              <div style="color:red"
                *ngIf="uploadVideoTaskStatusForm.get('uploadVideo').invalid && (uploadVideoTaskStatusForm.get('uploadVideo').dirty || uploadVideoTaskStatusForm.get('uploadVideo').touched)">
                <span *ngIf="uploadVideoTaskStatusForm.get('uploadVideo').errors?.required">File is required</span>
              </div>
            </div>
            <br />
          </div>
          <button type="button" style="margin-bottom:20px;margin-left: 10px;" class="btn btn-custom green"
            (click)="onUploadVideoDisplay1()" data-dismiss="modal">
            Close</button>&nbsp;
          <button class="btn btn-custom green" style="margin-bottom:20px;margin-left: 10px;" type="submit"
            [disabled]="uploadVideoTaskStatusForm.invalid">Save</button>
        </form>
      </div>
    </div>
  </div>
  
  <div class="modal modal-overlaybg" tabindex="1" role="dialog" [ngStyle]="{ display: uploadDroppVideoStatusDisplay }">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Upload 360 View Dropping Video To Customer</h4>
          <button (click)="onUploadDroppVideoDisplay1()" type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <form [formGroup]="uploadDroppingVideoForm" (ngSubmit)="onUploadDroppingVideoForVendor()">
          <div class="form-group col-lg-12">
            <div class="form-group">
              <input type="hidden" formControlName="taskId">
              <label>Upload Video<span class="text-danger">*</span></label>
              <input type="file" formControlName="uploadDroopCustomerVideo" id="dropFile" class="form-control" (change)="onDroppFileChange($event)">
              <div style="color:red"
                *ngIf="uploadDroppingVideoForm.get('uploadDroopCustomerVideo').invalid && (uploadDroppingVideoForm.get('uploadDroopCustomerVideo').dirty || uploadDroppingVideoForm.get('uploadDroopCustomerVideo').touched)">
                <span *ngIf="uploadDroppingVideoForm.get('uploadDroopCustomerVideo').errors?.required">File is required</span>
              </div>
            </div>
            <br />
          </div>
          <button type="button" style="margin-bottom:20px;margin-left: 10px;" class="btn btn-custom green"
            (click)="onUploadDroppVideoDisplay1()" data-dismiss="modal">
            Close</button>&nbsp;
          <button class="btn btn-custom green" style="margin-bottom:20px;margin-left: 10px;" type="submit"
            [disabled]="uploadDroppingVideoForm.invalid">Save</button>
        </form>
      </div>
    </div>
  </div>
  
  <app-loader [loaderActive]="loaderActive"></app-loader>