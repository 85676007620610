import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable,throwError  } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';


@Injectable()
export class AdminInterceptor implements HttpInterceptor {

  constructor(public router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Add the token to the request if it exists
    let admin_token = sessionStorage.getItem('token');
    if (admin_token) {
      request = this.addToken(request, admin_token);
    }

    // Pass the modified request to the next handler and handle errors
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // Redirect to login if a 401 Unauthorized error occurs
          this.router.navigate(['/admin/logout']);
        }
        return throwError(error);
      })
    );

  }


  private addToken(request: HttpRequest<any>, token : string ){       
    return request = request.clone({
      setHeaders: {  
        'Authorization': `Bearer `+ token
      }
    });

  }

  // private addToken(request: HttpRequest<any>, token : string ){       
  //   return request = request.clone({
  //     setHeaders: {  
  //       Authorization: `Bearer 1|Le5VNDSn9Fpr9iV1zl7lDrYRdSl0EyiK2PNgLl86`
        
  //     }
  //   });

  // }
}
